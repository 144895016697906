<template>
  <b-card>
    <div class="h6">Optionel, rechercher des éléments par texte</div>
    <b-input v-model="surveyListText" placeholder="Rechercher des survey, séparé par des ',' si plusieurs"></b-input>
    <b-btn v-if="surveyListText" @click="searchSurveys" class="my-2"><i class="mdi mdi-magnify"></i> Rechercher</b-btn>
    <div v-if="surveyListTextResults" class="ml-4">
      <div v-for="(s1,i) in surveyListTextSplit" :key="s1">
        <b>{{ s1 }}:</b> {{ surveyListTextResults[i] }}
      </div>
    </div>
  </b-card>
</template>

<script>
  export default {
    name:"f-select-surveys-by-search",
    props:['useLocal'],
    data() {
      return {
        surveyListText:null,
        allSurveys: null,
        surveys: null,
        surveyListTextResults: null,
      }
    },
    computed: {
      surveyListTextSplit(){
        return this.surveyListText.split(',')
      },
    },
    watch:{
      surveyListText(){
        this.surveyListTextResults=null
      },
      surveyListTextResults(){
        let rep = null
        if (this.surveyListTextResults){
          rep = this.surveyListTextResults.flat()
          if (rep.length==0){
            rep = null
          }
        }
        this.$emit('ids', rep)
      },
    },
    methods:{
      getAllSurveys(){
        let db = null
        if(this.useLocal){
          db = this.$store.dispatch('localDB/getDatabase',{dbName: this.$store.state.form.form_id, alwaysGetLocalDb: true})
        }else{
          db = this.$store.state.form.remoteDb
        }
        if (this.allSurveys){
          return Promise.resolve( this.allSurveys )
        }
        return this.$store.dispatch('form/fetchSurveysView', {useDb: db}).then(alldocs=>{
          //console.log(alldocs)
          this.allSurveys = alldocs.map(x=>{
            // let x1={_id:x.id,"editor_time":x.value[0],device:x.value[1]}
            let x1={_id:x.id}
            x1.value = x.value
            x1.hasConflicts = x.value[x.value.length - 1]
            return x1
          })
          return Promise.resolve(this.allSurveys)
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      },
      searchSurveys(){
        // First get all surveys view with the search terms.
        const iBegin = this.$store.getters['form/editListFields'].length - this.$store.getters['form/editListFieldsForm'].length
        const iEnd = iBegin + this.$store.getters['form/editListFieldsForm'].length - 1
        this.getAllSurveys().then(()=>{
          this.surveyListTextResults = this.surveyListTextSplit.map(search=>{
            const rep = this.allSurveys.filter(x=>{
              // check id also
              if (x._id == search){
                return true
              }
              for (let x2 = iBegin; x2 < iEnd+1; x2++) {
                if (x.value[x2] && x.value[x2].indexOf(search)!=-1){
                  return true
                }
              }
              return false
            }).map(x=>x['_id'])
            return rep
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>