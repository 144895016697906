export default{
  navbar:{
    formsLink:'Projets',
    formsLinkOffile:'Hors-Ligne',
    logout:'Déconnexion',
    login:'Connexion',
    help:'Aide',
    map:'Carte',
    currentForm:'Formulaire',
    adminSql:'Admin SQL',
    adminProjects:'Admin Projets',
    adminDevices:'Admin Équipements',
    adminUsers:'Admin Utilisateurs',
    configuration:'Configuration',
    user: 'Usager',
    refresh:"Recharger l'application",
    defaultFormLanguage:'Langue par défaut',
  },
  login:{
    connect:'Identifiez-vous',
    email:'Adresse e-mail',
    password:'Mot de passe',
    button:'Connexion',
    processing_message:'Connexion en traitement',
    error_message:'Erreur lors de la connexion',
    error_message_needAdmin:'Droits d\'administrateur nécessaires',
    hasLocalAccess: "Accéders aux formulaires hors-ligne sans se connecter",
  },
  projectsListTitle:"Liste des projets",
  projectsListTitle2:"Projets",
  addProject:"Ajout d'un projet",
  manager:"Responsable",
  project_no:"No de projet",
  project_label:"Description du projet",
  project_addLocally:"Ajout local terminé",
  project_addLocallyInprogress:"Ajout local en cours (ne pas fermer)",
  projectForm:{
    view:'Ouvrir le formulaire',
    makeOffLine:'Rendre accessible hors-ligne',
    remove:'Supprimer le formulaire',
    removeConfirm:'Confirmation de la suppression',
    newDefinition:'Nouvelle définition de formulaire',
  },
  addForm:"Ajout d'un formulaire",
  manageProjectMap:"Gestion de la cartographie",
  createProjectConfig:"Création de la cartographie du projet",
  deleted: 'Effacé',
  deletedShow: 'Voir effacés',
  manageProjectMapLabels:{
    syncButton:'Télécharger (sync) la cartographie locale',
    removeButton:'Supprimer la cartographie locale',
    removeButtonConfirm:'Confirmer la suppression de la cartographie locale',
    listFormLayers:'Formulaires - Couches géographiques',
    listLayers:'Liste des couches géographiques',
    manageConfig:'Configuration',
    manageImagery:'Imagerie',
    seedImagery:'Création imagerie',
    quota:'Quota',
    imagery:{ // for the imagery tab
      info:'Sélectionnez les niveaux de zoom à télécharger localement. Consultez le quota pour connaître la quantité de données possibles. ',
      database:'Base de données',
      imageSet:'Nom de l\'image',
      syncLocally:'Télécharger localement',
      remote:'Données serveur',
      local:'Données locales',
      total:'Total',
      selected:'Sélectionné',
      selectAll:'Selectionner tout',
      messBegin:'Téléchargement débuté',
      inProgress:'En cours => niveau',//'in progress => Level:'
      levelFinished:'Niveau terminé',// 'Finished Level:'
      syncFinished:'Téléchargement terminé',// Finished ALL
      syncError:'Erreur',
      syncErrorAutoRetry:'Erreur - Redémarrage automatique',
      filters: 'Filtrer par',
    }
  },
  cancel:"Annuler",
  save:"Sauvegarder",
  ok:"Ok",
  forms:{
    no:"Numéro du formulaire",
    label:"Description",
    comments:"Commentaires",
    uploadXmlFile:"Choisir un fichier formulaire JSON"
  },
  page_forms:{
    title:"Liste des formulaires disponibles localement(hors-ligne)",
    project:"Projet",
    form:"Formulaire",
    actions:'Actions',
    otherData:'Autres données',
    titleProject:"Liste des projets disponibles localement(hors-ligne)",
    titleOther:"Liste des autres données disponibles localement(hors-ligne)",
  },
  form:{
    newSurvey:"Nouvelle saisie",
    editSurvey:"Éditer saisie",
    deleteSurvey:'Supprimer saisie',
    deleteSurveyConfirm:"Confirmer la suppression",
    syncMenu:"Synchronisation",
    summaryMenu: 'Sommaires de données',
    viewsMenu: 'Visualisations de données',
    summarySelect: 'Sélectionner le sommaire à visualiser',
    configDownload:"Téléchargement des photos",
    configMenu:"Configuration",
    emptyFormLabel:'Formulaire vide pour impression',
    configIdCol:'Propriété',
    configValueCol:'Valeur',
    versionNumber:'Version',
    formNotOffline: "Le formulaire n'est pas présent localement",
    formNotOffline2: "Basculer en mode données serveur et voir le formulaire",
    sync:{
      syncPush:'Envoi de données',
      syncPull:'Obtenir des données',
      sendData:'Envoyez les données locales',
      nbNotSync:'Nombre d\'éléments à envoyer',
      deletedWarming:'Les éléments effacés n\'appaissent pas ici, mais seront synchronisés',
      offline:'Synchronisation impossible - L\'ordinateur n\'est pas connecté à Internet', 
      notLocal:'Le fomulaire n\'est pas présent localement, pas de synchronisation nécessaire',
      inProgress:'Synchronisation en cours',
      inProgressFeatures:'Nombre d\'éléments envoyés',
      complete:'Synchronisation terminée',
      error:'Erreur lors de la synchronisation',
      return: 'Retour au formulaire',
      returnLocalForms: 'Retour aux formulaires local(hors-ligne)',
      deletelocals:'Supprimer les données locales',
      deletelocalsMess:'Attention!!! Toutes les données locales seront supprimées, assurez-vous d\'avoir terminé la saisie.' ,
      deletelocals2:'Supprimer les données locales et le formulaire localement',
      deletelocals2Mess:'Attention!!! Toutes les données locales seront supprimées ainsi que le formulaire local.' ,
      deletelocalsProgress:'Suppression des données locales en cours',
      deletelocalsDone:'Les données locales ont été supprimées',
      syncOptionsTitle:"Options, selon votre connexion Internet",
      syncOptions:{
        '1':'1 - Lent, très fiable',
        '2':'2 - Lent, fiable',
        '3':'3 - Moyen',
        '4':'4 - Rapide',
        '5':'5 - Ultra'
      },
      projectSync:'Synchronisation du projet...',
      syncTabInfo:'Information',
      syncTabInfoText:'Sélectionner un onglet afin de voir plus d\'information sur la synchronisation',
      syncTabListSurveys:'Liste à envoyer',
      syncTabCompare:'Comparer avec serveur',
      syncIncludePhotos: 'Inclure les photos',
      pull:{
        title: 'Obtenir des données du serveur',
        title2: 'Les données seront enregistrés dans les données locales et pourront ensuite être éditées',
        hasLocalNot: 'Les données locales ne sont pas déjà créés, elles le seront lors de l\'obtention des données du serveur',
        notInSettings: 'Cette fonctionalité est déactivé par l\'adminisatrateur... ',
        buttonText: 'Obtenir',
        note1: 'Si la présente fonction venait qu\à s\'arrêter, planter ou autre problème, il est sécuritaire de seulement la repartir. Après avoir fait un refraîchissement de la page.',
        jsonTitle: "Obtenir les données d'un fichier de transfert JSON",
        jsonTitle2: "Faire attention, les données locales devraient être toutes effacés avant afin d'éviter des conflits ou s'assurer de ne pas avoir de recoupement",
        jsonFile: "Choisissez un ou plusieurs fichiers",
        jsonCount: "Nombres de fichiers à transférer",
        jsonFileError: "Ce fichier n'est pas dans un format supporté",
        jsonInProgress: "Transfert en cours, veillez patienter et ne pas quitter cette page",
        jsonError: "Erreur de transfert",
        jsonEnd: "Transfert terminé et réussi",
      }
    },
    map:'Carte',
    nextPage:'Suivant',
    previousPage:'Précédent',
    finish:'Terminer',
    finishWithError:'Terminé avec erreur',
    finishNoSave:'Sauvegarder (Échec de la sauvegarde, les données seront perdues si vous quittez la page, veillez cliquer de nouveau lorsque vous aurez une connection internet)',
    suggestOfflineMode:'Attention, vous devriez être en mode hors ligne - En mode en ligne, des données pourraient être perdues. Nous vous conseillons vivement de passer en mode hors ligne.',
    isDeleted: 'Attention, cet élément a été effacé',
    isDeletedRestore: 'Restaurer cet élément',
    isLocal:'Version locale active',
    isLocalFalse:'Version serveur active',
    viewOnlineVerison:'Voir la version serveur',
    viewOfflineVerison:'Voir la version locale (hors-ligne)',
    summaryButton:'Sommaire',
    summary:{
      backToEdit:'Retour à l\'édition',
      gotoFormStart:'Aller à la première page',
      gotoFormEnd:'Aller à la dernière page',
      creatorId: 'Créateur',
      editorId: 'Dernier éditeur',
      beginEdit: 'Date/heure de début d\'édition',
      endClicked: 'Date/heure "Teminer" cliqué',
    },
    migrateSurveyFormDefinition:'Mise à jour de la définition à la dernière version',
    conflicts:'Conflits d\'édition',
    conflictsSolve:'Résourdre les conflits en conservant uniquement les éditions du créateur(device)',
    conflictsMess1: 'Ok conflit résolu en conservant la révision: ',
    conflictsMess2: 'Plus d\'une révision correspond à l\'appareil de création (device_id)',
    conflictsMess3: 'Aucune révison ne correspond',
    emptyForm:{ 
      allPages:'Voir toutes les pages'
    },
    edit:{
      fields:{
        'action':'Actions',
        'creationTime':'Création',
        'editor_time':'Édition',
        'editor_device_id':'Appareil d\'édition',
        'creator':'Créateur',
        'editor':'Éditeur',
        'device_id':'Appareil de création',
        'form_status_global': 'État du formulaire',
      },
      download: 'Télécharger localement (hors-ligne) ces formulaires',
      downloadIncludePhotos: 'Inclure les photos',
      downloadIncludePhotosNot: 'Les photos ne seront pas incluses',
      valueNotInList: 'Attention: La valeur sélectionné n\'est pas dans la liste des choix. Source possible: Changement de definition ou list d\'options dynamique',
      records: ' entrées',
      recordsAll: 'Toutes les entrées',
      oneDate: 'Une date',
    },
    editNotLocalButRemote:{
      title:"Cette entrée du formulaire n'est pas présente localement (hors-ligne), mais présent sur le serveur",
      optViewOnline:"Basculer en mode données serveur et voir l'entrée du formulaire",
    },
    list:{
      searchPlaceholder: 'Rechercher une saisie'
    },
    flags:{
      'flag-raised':'Avertissement créé',
      'flag-solved':'Avertissement résolu',
      'flag-validated':'Avertissement validé',
    },
  },
  form_select:{
    configureOrder:'Configurer l\'ordre',

  },
  status:{ //label key in form_util.js
    0:'Ok',
    4:'Ok-pas nécessaire', // not relevent
    5:'Ok-vide',
    10:'Pas dans la liste',
    15:'Condition non remplie',
    20:'Requis',
  },
  status_message:{ //label key in form_util.js
    notInList:'Pas dans la liste',
    doNotRespectConstraint:'Condition non remplie',
    required:'Requis',
    flagRaised: 'Avertissement créé',
  },
  inputs:{
    add:'Ajout',
    delete:'Supprimer',
    edit:'Editer',
    latitude:'Latitude',
    longitude:'Longitude',
    accuracy:'Précision',
    altitude: 'Altitude (z)',
    typePoint:'Type',
    typePolygon:'Type',
    geoshapeManualFormat: 'Entrer une geometrie au format WKT - projection latitude-longitude (EPSG:4326)',
    clearPoint:'Modifier',
    area:'Superficie (m²)',
    nbCoordinates: 'Nombre de points',
    GpsLastUpdate:'Dernière mise à jour GPS',
    GpsAgeSeconds:'s',
    GpsAgeMinutes:'m',
    geoshapeNotNormalValue:'La valeur de la géométrie n\'est pas correcte',
  },
  map:{
    addPoint:'Ajouter un point',
    addPointGPS:'Ajouter un point GPS',
    position_update_recenter:'Recentrer position GPS ',
    endEdit:'Fin',
    accuracy:'Précision',
    tableOfContent:'Couches', //Table of content
    surveyLayerVisibleOptions:{
      onlyShowMySurvey:'Seulement mon formulaire',
      fadeOtherSurveys:'Autres formulaires grisés',
      allSurveys:'Tous les formulaires',
      defaultVal:'Par défaut',
    },
  },
  deviceConfig:{
    deviceName:'Nom de l\'appareil ou de l\'utilisateur',
    deviceNumber:'Numéro de l\'appareil',
    save:'Sauvegarder la configuration',
    titleConfigServer:'Configuration envoyé au serveur',
    titleConfigLocal:'Configuration locale',
    noteConfigLocal:'La configuration locale est sauvegardé automatiquement',
    mapIdentityPixel:'Carte - Tolérance pour click sur un point en pixel',
    photoMaxPixelSize:'Photo - Taille maximale en pixel',
  },
  quota:{
    title:'Données locales',
    usage:'Utilisation actuelle',
    quota:'Utilisation maximale',
    percent:'Pourcentage d\'utilisation'
  },
  admin:{
    deviceList:{
      title:'Liste des équipements nommés',
      fields:{
        '_id':'numéro unique',
        deviceNumber:'Numéro de l\'appareil',
        deviceName:'Nom de l\'appareil'
      }
    },
    users:{
      title:'Manage user and user permissions',
      fields:{
        '_id':'Identifiant',
        name:'Nom de l\'utilisateur',
        roles2:'Roles'
      }
    }
  },
  project:{
    sync:{
      return: 'Retour au formulaires',
      sendData:'Envoyer les données locales du project',
      deletelocals:'Supprimer les données locales',
      deletelocalsMess:'Attention!!! Toutes les données locales seront supprimées, assurez-vous d\'avoir terminié la saisie.' ,
      globalError:'Attention!!! Échec de l\'opération'
    },
  },
  helpPage:{
    title:'Aide',
    appVersion:'Version de l\'application',
    tab_database:'Base de données',
    // tab_debug:'Débogage',
    tab_tools:'Outils',
    tab_functionalities:'Fonctionnalités',
    selectDbServer: 'Sélection d\'un server de BD',
    ipCertificate: 'Télécharger le certificat d\'autorité (pour utilisation avec un serveur IP)',
    localDataDeletion: 'Permettre la suppression des données locales',
    localDataDeletionButton: 'DANGER - permettre la suppression des données locales sans synchronisation préalable - Risque de perte de données',
    localDataDeletionButton2: 'Désactiver - permettre la suppression des données locales',
    copypaste: 'Permettre la copie ou le collage de données (utiliser avec prudence)',
    copypasteMessage: 'Fonctionne bien dans Chrome desktop (doit être autorisé lorsqu\'il est demandé). Ne fonctionne pas dans Firefox. Sur les appareils mobiles, ne doit pas être utilisé, non testé.',
    copypasteButton: 'DANGER - permettre la copie ou le collage de données - Risque de perte de données si mal utilisé',
    copypasteButton2: 'Désactiver - permettre la copie ou le collage de données',
    saveAppMessages: 'Sauvegarder les messages de l\'application (pour débogage)',
    saveAppMessagesButton: 'Activer la sauvegarde',
    saveAppMessagesButton2: 'Déactiver la sauvegarde',
    saveAppMessagesButton3: 'Envoyer les messages sauvegardés au serveur',
    allowRepeatReorders: 'Permettre de modifier l\'ordonnancement des listes',
    allowRepeatReordersButton: 'Activer l\'ordonnancement',
    allowRepeatReordersButton2: 'Désactiver l\'ordonnancement',
  },
  messages:{
    geometryEditChanged:`<b>Notez que la méthode de saisie des géométries a changé</b> 
      <br> - Lors de l'ajout de point, il faut maintenant cliquer le bouton à droite pour confirmer le point.
      <br> - Lors de l'ajout de polygone, il faut maintenant cliquer le bouton à droite pour confirmer le polygone.`,
  }
}
