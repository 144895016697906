export default{
  navbar:{
    formsLink:'Projects',
    formsLinkOffile:'Offline',
    logout:'Logout',
    login:'Login',
    help:'Help',
    map:'Map',
    currentForm:'Form',
    adminSql:'Admin SQL',
    adminProjects:'Admin Projects',
    adminDevices:'Admin Devices',
    adminUsers:'Admin Users',
    configuration:'Configuration',
    user: 'User',
    refresh:'Refresh app',
    defaultFormLanguage:'Default Language',
  },
  login:{
    connect:'Please Connect',
    email:'E-mail',
    password:'Password',
    button:'Login',
    processing_message:'Connection in progress',
    error_message:'Error while connecting',
    error_message_needAdmin:'Administrator Privileges Needed',
    hasLocalAccess: "Access to local data (offline) forms without login",
  },
  projectsListTitle:"Project List",
  projectsListTitle2:"Projects",
  addProject:"Add Project",
  manager:"Manager",
  project_no:"Project No",
  project_label:"Project Description",
  project_addLocally:"Locally Adding Completed",
  project_addLocallyInprogress:"Locally Adding in Progress (Do not close)",
  projectForm:{
    view:'Open Form',
    makeOffLine:'Make Available Locally(Offline)',
    remove:'Remove Form',
    removeConfirm:'Removing Form Confirmation',
    newDefinition:'Form New Definition',
  },
  addForm:"Add Form",
  manageProjectMap:"Map Management",
  createProjectConfig:"Create Project Map",
  deleted: 'Deleted',
  deletedShow: 'Show deleted',
  manageProjectMapLabels:{
    syncButton:'Download (sync) Local Map ',
    removeButton:'Remove Local Map ',
    removeButtonConfirm:'Confirm Remove Local Map',
    listFormLayers:'Forms - Geographical Layers',
    listLayers:'Geographical Layers List',
    manageConfig:'Configuration',
    manageImagery:'Imagery',
    seedImagery:'Imagery Creation',
    quota:'Quota',
    imagery:{ // for the imagery tab
      info:'Select desired zoom levels to download locally. Consult quota to guide the amount of possible data. ',
      database:'Database',
      imageSet:'Image Name',
      syncLocally:'Download Locally',
      remote:'Server Data',
      local:'Local Data',
      total:'Total',
      selected:'Selected',
      selectAll:'Select All',
      messBegin:'Download Started',
      inProgress:'In progress => Level',//'in progress => Level:'
      levelFinished:'Level Finished',// 'Finished Level:'
      syncFinished:'Download complete',// Finished ALL
      syncError:'Error',
      syncErrorAutoRetry:'Error - Automatic Restart',
      filters: 'Filter by',
    }
  },
  cancel:"Cancel",
  save:"Save",
  ok:"Ok",
  forms:{
    no:"Form No",
    label:"Description",
    comments:"Comments",
    uploadXmlFile:"Choose a JSON Form File"
  },
  page_forms:{
    title:"List of Forms Available Locally(Offline)",
    project:"Project",
    form:"Form",
    actions:'Actions',
    otherData:'Other Data',
    titleProject:"List of Projets Available Locally(Offline)",
    titleOther:"Liste of Other Data Available Locally(Offline)",
  },
  form:{
    newSurvey:"New Entry",
    editSurvey:"Edit Entry",
    deleteSurvey:'Delete Entry',
    deleteSurveyConfirm:"Confirm Delete Entry",
    syncMenu:"Synchronization",
    summaryMenu: 'Summaries tables',
    viewsMenu: 'Views of entries',
    summarySelect: 'Select the sumarry to view',
    configDownload:"Download photos",
    configMenu:"Configuration",
    emptyFormLabel:'Blank Form for Printing',
    configIdCol:'Property',
    configValueCol:'Value',
    versionNumber:'Version',
    formNotOffline:"Form not present in local data, but present on the server",
    formNotOffline2:"Switch to server mode and see the form",
    sync:{
      syncPush:'Send data',
      syncPull:'Get data',
      sendData:'Send Local Data',
      nbNotSync:'Number of items to send',
      deletedWarming:'Erased items do not appear here, but will be synchronized',
      offline:'Synchronization impossible - Computer is not connected to Internet',
      notLocal:'Form is not present locally - No synchronization required',
      inProgress:'Synchronization in progress',
      inProgressFeatures:'Number of Sent Entries',// 'Features??'
      complete:'Synchronization Completed',
      error:'Error During Synchronization',
      return: 'Return to Form',
      returnLocalForms: 'Return to Local(Offline) Forms',
      deletelocals:'Erase Local Data',
      deletelocalsMess:'Warning!!! Local data will be deleted, make sure you have completed data entry.' ,
      deletelocals2:'Erase Local Data and Local Form',
      deletelocals2Mess:'Warning!!! Local data and form will be deleted.' ,
      deletelocalsProgress:'Deleting Local Data in Progress',
      deletelocalsDone:'Local Data Deleted',
      syncOptionsTitle:"Options, according to your Internet connection",
      syncOptions:{
        '1':'1 - Slow, very reliable',
        '2':'2 - Slow, reliable',
        '3':'3 - Average',
        '4':'4 - Fast',
        '5':'5 - Ultra'
      },
      projectSync:'Project Synchronization...',
      syncTabInfo:'Info',
      syncTabInfoText:'Select a tab to see more sync information',
      syncTabListSurveys:'List to send',
      syncTabCompare:'Compare with server',
      syncIncludePhotos: 'Include photos',
      pull:{
        title: 'Get data from server',
        title2: 'The data are gone a be downloaded locally and they will be available for edition them',
        hasLocalNot: 'Local data are not already present, the operation will create them',
        notInSettings: 'This functionality is deactiactivated by the adminsitrator... ',
        buttonText: 'Get data',
        note1: 'If this operation crash, it\'s safe to restart it after a page refresh.',
        jsonTitle: "Get data from a JSON transfer file",
        jsonTitle2: "Be careful, local data should be empty before beginning to avoid conflicts or ensure no data overlaps exists",
        jsonFile: "Choose a file",
        jsonCount: "Number of files to transfer",
        jsonFileError: "This file is not compatible",
        jsonInProgress: "Transfer in progress, please be patient and don't quit this page",
        jsonError: "Transfer errors",
        jsonEnd: "Transfer ended and successfull",
      }
    },    
    map:'Map',
    nextPage:'Next',
    previousPage:'Previous',
    finish:'Finish',
    finishWithError:'Finish with Error',
    finishNoSave:'Save (saving was not successful, data will be lost if you quit this page, please click again when you have an internet connection)',
    suggestOfflineMode:'Careful, you should be in offline mode - In online mode, data could be lost. We strongly advise to switch to offline mode.',
    isDeleted: 'Caution: This entry has been deleted',
    isDeletedRestore: 'Restore this entry',
    isLocal:'Local Version Active',
    isLocalFalse:'Server Version Active',
    viewOnlineVerison:'See Server Version',
    viewOfflineVerison:'See Local Version (Offline)',
    summaryButton:'Summary',
    summary:{
      backToEdit:'Back to Edit',
      gotoFormStart:'Go to First Page',
      gotoFormEnd:'Go to Last Page',
      creatorId: 'Creator',
      editorId: 'Last editor',
      beginEdit: 'Timestamp begin edit',
      endClicked: 'Timestamp "End" clicked',
    },
    migrateSurveyFormDefinition:'Update survey definition to latest version',
    conflicts:'Editing conflicts',
    conflictsSolve:'Resolve conflicts keeping only creator(device) editions',
    conflictsMess1: 'Ok solved keeping revision: ',
    conflictsMess2: 'More than one edition that match the creator device_id',
    conflictsMess3: 'No matching revision',
    emptyForm:{ 
      allPages:'See All Pages'
    },
    edit:{
      fields:{
        'action':'Actions',
        'creationTime':'Creation time',
        'editor_time':'Editor time',
        'editor_device_id':'Editor device',
        'creator':'Creator',
        'editor':'Editor',
        'device_id':'Creator device',
        'form_status_global': 'Survey status',
      },
      download: 'Download locally (offline) those surveys',
      downloadIncludePhotos: 'Include photos',
      downloadIncludePhotosNot: "Photos won't be included",
      valueNotInList: 'Caution: The selected value is not in the list of choices. Possible causes: form definition change or dynamic choices list',
      records: ' surveys',
      recordsAll: 'All surveys',
      oneDate: 'One date',
    },
    editNotLocalButRemote:{
      title:"Survey not present in local data, but present on the server",
      optViewOnline:"Switch to server mode and see the survey",
    },
    list:{
      searchPlaceholder: 'Search survey'
    },
    flags:{
      'flag-raised':'Flag raised',
      'flag-solved':'Flag solved',
      'flag-validated':'Flag validated',
    },
  },
  form_select:{
    configureOrder:'Configure Order',

  },
  status:{ //label key in form_util.js
    0:'Ok',
    4:'Ok - Not relevant', // not relevent
    5:'Ok - Empty',
    10:'Not in the list',
    15:'Do not Respect Constraint',
    20:'Required',
  },
  status_message:{ //label key in form_util.js
    notInList:'Not in the list',
    doNotRespectConstraint:'Do not Respect Constraint',
    required:'Required',
    flagRaised: 'Flag raised',
  },
  inputs:{
    add:'Add',
    delete:'Delete',
    edit:'Edit',
    latitude:'Latitude',
    longitude:'Longitude',
    accuracy:'Accuracy',
    altitude: 'Altitude (z)',
    typePoint:'Type',
    typePolygon:'Type',
    geoshapeManualFormat: 'Enter a geometry in WKT format - latitude and longitude projection (EPSG:4326)',
    clearPoint:'Modify',
    area:'Area (m²)',
    nbCoordinates: 'Number of points',
    GpsLastUpdate:'GPS Last Update',
    GpsAgeSeconds:'s',
    GpsAgeMinutes:'m',
    geoshapeNotNormalValue:'Not a valid geometry',
  },
  map:{
    addPoint:'Add Point',
    addPointGPS:'Add GPS Point ',
    position_update_recenter:'Recenter GPS position',
    endEdit:'End',
    accuracy:'Accuracy',
    tableOfContent:'Table of Content', //Table of content
    surveyLayerVisibleOptions:{
      onlyShowMySurvey:'Only Show My Surveys',
      fadeOtherSurveys:'Fade Other Surveys',
      allSurveys:'All Surveys',
      defaultVal:'Default',
    },
  },
  deviceConfig:{
    deviceName:'Device Name or User Name',
    deviceNumber:'Device Number',
    save:'Save Configuration',
    titleConfigServer:'Configuration sent to Server',
    titleConfigLocal:'Configuration kept Locally',
    noteConfigLocal:'Changes made locally are automatically saved',
    mapIdentityPixel:'Map Identity Pixel Tolerance',
    photoMaxPixelSize:'Photo Max Pixel Size',
  },
  quota:{
    title:'Local Data',
    usage:'Current Use',
    quota:'Quota',
    percent:'Percentage of Use'
  },
  admin:{
    deviceList:{
      title:'Device List',
      fields:{
        '_id':'ID',
        deviceNumber:'Device Number',
        deviceName:'Device Name'
      }
    },
    users:{
      title:'Manage user and user permissions',
      fields:{
        '_id':'User ID',
        name:'Name',
        roles2:'Roles'
      }
    }
  },
  project:{
    sync:{
      return: 'Return to Forms',
      sendData:'Send Project Local Data',
      deletelocals:'Delete Local Data',
      deletelocalsMess:'Warning!!! Local data will be deleted, make sure you have completed data entry.' ,
      globalError:'Warning!!!, Operation Failed'
    },
  },
  helpPage:{
    title:'Help',
    appVersion:'Application version',
    tab_database:'Database',
    // tab_debug:'Débogage',
    tab_tools:'Tools',
    tab_functionalities:'Functionalities',
    selectDbServer: 'Select DB server',
    ipCertificate: 'Download Certificate Authority (for use with IP server)',
    localDataDeletion: 'Allow local data deletion',
    localDataDeletionButton: 'DANGER - allow to delete local data without previuos sync - Potential data lost possible',
    localDataDeletionButton2: 'Disable - allow to delete local data without previuos sync',
    copypaste: 'Allow copy or paste data (use with caution)',
    copypasteMessage: 'Works ok in Chrome desktop (has to allow it when asked). Does not work in Firefox. On mobile devices should not be used, not tested.',
    copypasteButton: 'DANGER - allow to copy or paste data - Potential data lost possible if not used correctly',
    copypasteButton2: 'Disable - allow to copy or paste data',
    saveAppMessages: 'Save application messages - used for debugging',
    saveAppMessagesButton: 'Activate saving',
    saveAppMessagesButton2: 'Disable saving',
    saveAppMessagesButton3: 'Send saved messages to server',
    allowRepeatReorders: 'Allow repeat reorders - list elements reordering',
    allowRepeatReordersButton: 'Allow repeat reorders',
    allowRepeatReordersButton2: 'Disable repeat reorders',
  },
  messages:{
      geometryEditChanged:`<b>Note that the geometry entry method has changed</b> <br> 
        - When adding a point, you must now click the button on the right to confirm the point. <br> 
        - When adding a polygon, you must now click the button on the right to confirm the polygon.`,
  }
}
